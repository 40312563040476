import { InsetInput, Group, Hint, InsetNativeSelect } from '@heathmont/moon-core-tw';
import { IFlightBookingPassengerForm } from './types';
import { genderOptions } from '../../enums/GenderType';
import { countyCodes, formatPhone } from '../../utils/phone';
import { useEffect } from 'react';
import SelectOptions from '../SelectOptions';
import LoyaltyProgramSelector from '../LoyaltyProgramSelector';
import { fieldWrapperCN, groupCN } from '../../styles/form-field';
import AdditionalBaggageSelector from '../AdditionalBaggageSelector';
import { GenericInfo } from '@heathmont/moon-icons-tw';
import { isDocumentExpiring } from './utils';

const FlightBookingPassengerForm: React.FC<IFlightBookingPassengerForm> = ({
  travelDocumentsTypes,
  additionalBagError,
  additionalBags,
  countries,
  passenger,
  airlines,
  touched,
  errors,
  bags,
  handleSelectBaggage,
  setFieldTouched,
  setFieldValue,
}) => {
  useEffect(() => {
    if (touched?.phoneCountryCode || touched?.phoneNumber) {
      setFieldTouched('phone');
    }
  }, [touched?.phoneCountryCode, touched?.phoneNumber]);

  useEffect(() => {
    setFieldValue('phone', formatPhone(passenger?.phoneCountryCode, passenger?.phoneNumber));
  }, [passenger?.phoneCountryCode, passenger?.phoneNumber]);

  const getFieldBaseProps = (field) => {
    return {
      name: `${field}_${passenger.id}`, 
      id: `${field}_${passenger.id}`,
    };
  };

  return (
    <div className='w-full flex flex-col gap-[16px]'>
      <div className={fieldWrapperCN}>
        <InsetNativeSelect
          {...getFieldBaseProps('gender')}
          className='rounded-moon-i-md [&_select]:rounded-moon-i-md'
          error={!!(errors?.gender && touched?.gender)}
          value={passenger?.gender || ''}
          label={'Gender'}
          onChange={({ target: { value } }) => setFieldValue('gender', value)}
          onBlur={() => setFieldTouched('gender')}
        >
          <SelectOptions options={genderOptions} label='gender' />
        </InsetNativeSelect>

        {!!(errors?.gender && touched?.gender) && (
          <Hint error>{errors?.gender}</Hint>
        )}
      </div>

      <div className={fieldWrapperCN}>
        <Group className={groupCN}>
          <Group.FirstInsetInput
            {...getFieldBaseProps('firstName')}
            placeholder=' '
            error={!!(errors?.firstName && touched?.firstName)}
            value={passenger.firstName}
            onChange={({ target: { value } }) => setFieldValue('firstName', value)}
            onBlur={() => setFieldTouched('firstName')}
          >
            <InsetInput.Label>First name as in your passport</InsetInput.Label>
          </Group.FirstInsetInput>
          <Group.LastInsetInput
            {...getFieldBaseProps('lastName')}
            placeholder=' '
            error={!!(errors?.lastName && touched?.lastName)}
            value={passenger.lastName}
            onChange={({ target: { value } }) => setFieldValue('lastName', value)}
            onBlur={() => setFieldTouched('lastName')}
          >
            <InsetInput.Label>Last name as in your passport</InsetInput.Label>
          </Group.LastInsetInput>
        </Group>

        {!!(errors?.firstName && touched?.firstName) && (
          <Hint error>{errors?.firstName}</Hint>
        )}
        {!!(errors?.lastName && touched?.lastName) && (
          <Hint error>{errors?.lastName}</Hint>
        )}
      </div>     
      
      <div className={fieldWrapperCN}>
        <Group className={groupCN}>
          <Group.FirstInsetInput
            {...getFieldBaseProps('birthday')}
            placeholder='Birth date'
            error={!!(errors?.birthday && touched?.birthday)}
            value={passenger.birthday}
            type='date'
            onChange={({ target: { value } }) => setFieldValue('birthday', value || '')}
            onBlur={() => setFieldTouched('birthday')}
          >
            <InsetInput.Label>Birth date</InsetInput.Label>
          </Group.FirstInsetInput>
          <Group.LastInsetInput
            {...getFieldBaseProps('email')}
            placeholder=' '
            error={!!(errors?.email && touched?.email)}
            value={passenger.email}
            type='email'
            onChange={({ target: { value } }) => setFieldValue('email', value)}
            onBlur={() => setFieldTouched('email')}
          >
            <InsetInput.Label>Email</InsetInput.Label>
          </Group.LastInsetInput>
        </Group>
    
        {!!(errors?.birthday && touched?.birthday) && (
          <Hint error>{errors?.birthday}</Hint>
        )}
        {!!(errors?.email && touched?.email) && (
          <Hint error>{errors?.email}</Hint>
        )}
      </div>
      
      <div className={fieldWrapperCN}>
        <Group className={groupCN}>
          <Group.FirstInsetSelect
            {...getFieldBaseProps('phoneCountryCode')}
            error={!!(errors?.phoneCountryCode && touched?.phoneCountryCode) || !!(errors?.phone && touched?.phone)}
            value={passenger.phoneCountryCode || ''}
            label={'Country'}
            onChange={({ target: { value } }) => setFieldValue('phoneCountryCode', value)}
            onBlur={() => setFieldTouched('phoneCountryCode')}
          >
            <SelectOptions options={countyCodes} label='country' />
          </Group.FirstInsetSelect>
          <Group.LastInsetInput
            {...getFieldBaseProps('phoneNumber')}
            placeholder=' '
            error={!!(errors?.phoneNumber && touched?.phoneNumber) || !!(errors?.phone && touched?.phone)}
            value={passenger.phoneNumber}
            type='number'
            onChange={({ target: { value } }) => setFieldValue('phoneNumber', value)}
            onBlur={() => setFieldTouched('phoneNumber')}
          >
            <InsetInput.Label>Telephone number</InsetInput.Label>
          </Group.LastInsetInput>
        </Group>
    
        {!!(errors?.phoneCountryCode && touched?.phoneCountryCode) && (
          <Hint error>{errors?.phoneCountryCode}</Hint>
        )}
        {!!(errors?.phoneNumber && touched?.phoneNumber) && (
          <Hint error>{errors?.phoneNumber}</Hint>
        )}
        {!!(touched?.phone && touched?.phone && !errors?.phoneCountryCode && !errors?.phoneNumber && errors?.phone) && (
          <Hint error>{errors?.phone}</Hint>
        )}
      </div>

      {passenger?.documentRequired && (
        <>
          <div className={fieldWrapperCN}>
            <Group className={groupCN}>
              <Group.FirstInsetSelect
                {...getFieldBaseProps('document.typeId')}
                error={!!(errors?.document?.typeId && touched?.document?.typeId)}
                value={passenger.document.typeId || ''}
                label={'Document type'}
                onChange={({ target: { value } }) => setFieldValue('document.typeId', value)}
                onBlur={() => setFieldTouched('document.typeId')}
              >
                <SelectOptions options={travelDocumentsTypes} label='document type' />
              </Group.FirstInsetSelect>
              <Group.LastInsetSelect
                {...getFieldBaseProps('document.countryId')}
                error={!!(errors?.document?.countryId && touched?.document?.countryId)}
                value={passenger.document.countryId || ''}
                label={'Country issued document'}
                onChange={({ target: { value } }) => setFieldValue('document.countryId', value)}
                onBlur={() => setFieldTouched('document.countryId')}
              >
                <SelectOptions options={countries} label='country issued document' />
              </Group.LastInsetSelect>
            </Group>

            {!!(errors?.document?.typeId && touched?.document?.typeId) && (
              <Hint error>{errors?.document?.typeId}</Hint>
            )}

            {!!(errors?.document?.countryId && touched?.document?.countryId) && (
              <Hint error>{errors?.document?.countryId}</Hint>
            )}
          </div>

          <div className={fieldWrapperCN}>
            <Group className={groupCN}>
              <Group.FirstInsetInput
                {...getFieldBaseProps('document.number')}
                placeholder=' '
                error={!!(errors?.document?.number && touched?.document?.number)}
                value={passenger.document.number}
                type='text'
                onChange={({ target: { value } }) => setFieldValue('document.number', value)}
                onBlur={() => setFieldTouched('document.number')}
              >
                <InsetInput.Label>Document number</InsetInput.Label>
              </Group.FirstInsetInput>
            
              <Group.LastInsetInput
                {...getFieldBaseProps('document.validTill')}
                placeholder=' '
                error={!!(errors?.document?.validTill && touched?.document?.validTill)}
                value={passenger.document.validTill}
                type='date'
                onChange={({ target: { value } }) => setFieldValue('document.validTill', value || '')}
                onBlur={() => setFieldTouched('document.validTill')}
              >
                <InsetInput.Label>Document expiration date</InsetInput.Label>
              </Group.LastInsetInput>
            </Group>

            {!!(errors?.document?.number && touched?.document?.number) && (
              <Hint error>{errors?.document?.number}</Hint>
            )}
            {!!(errors?.document?.validTill && touched?.document?.validTill) && (
              <Hint error>{errors?.document?.validTill}</Hint>
            )}
            {!(errors?.document?.number && touched?.document?.number) && !(errors?.document?.validTill && touched?.document?.validTill) && (touched?.document?.validTill && isDocumentExpiring(passenger?.document?.validTill)) && (
              <Hint>
                <GenericInfo />
                Document is expiring in the next 6 months
              </Hint>
            )}
          </div>
        </>
      )}

      <AdditionalBaggageSelector
        selectedOptions={additionalBags}
        options={bags}
        error={additionalBagError}
        onSelect={handleSelectBaggage}
      />

      <LoyaltyProgramSelector
        loyaltyNumber={passenger.loyaltyNumber}
        passengerId={passenger.id}
        airlines={airlines}
        touched={touched?.loyaltyNumber}
        errors={errors?.loyaltyNumber}
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
      />
    </div>
  );
};

export default FlightBookingPassengerForm;
