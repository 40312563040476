import moment from 'moment';

export const isDocumentExpiring = (validTill: string): boolean => {
  const MAX_DAYS_DOCUMENT_VALID_BEFORE_EXPIRATION = 180;

  if (!validTill) {
    return false;
  }

  const lastDayDocumentValid = moment().add(MAX_DAYS_DOCUMENT_VALID_BEFORE_EXPIRATION, 'days');
  return moment(validTill).isBefore(lastDayDocumentValid);
};
