import { useEffect, useState } from 'react';
import AdditionalBaggageSelector from '../AdditionalBaggageSelector';
import LoyaltyProgramSelector from '../LoyaltyProgramSelector';
import UsersSearchSelect from '../UsersSearchSelect';
import { IFlightBookingPassengerForm } from './types';
import { handleFetchWarnings } from './utils';

const InternalPassengerForm: React.FC<IFlightBookingPassengerForm> = ({
  additionalBagError,
  additionalBags,
  passenger,
  airlines,
  touched,
  errors,
  users,
  bags,
  handleSelectBaggage,
  setFieldTouched,
  setFieldValue,
  setValues,
}) => {
  const [warning, setWarning] = useState(null);
 
  const handleChangePassenger = (userId) => {
    const userData = users.find(user => +user.id === +userId);
    setValues({
      ...passenger,
      firstName: userData.firstName,
      lastName: userData.lastName,
      userId,
      email: userData.email,
    });
  };

  useEffect(() => {
    if (passenger?.userId) {
      handleFetchWarnings(passenger?.userId, setWarning);
    }
  }, [passenger?.userId]);

  return (
    <div className='w-full flex flex-col gap-[16px]'>
      <UsersSearchSelect
        placeholder='Select passenger'
        isWarning={!!(warning && touched?.userId)}
        warning={warning}
        isError={!!(errors?.userId && touched?.userId)}
        error={errors?.userId}
        label={'Passenger'}
        users={users}
        value={passenger?.userId}
        setTouched={() => setFieldTouched('userId')}
        onChange={(value) => handleChangePassenger(value)}
      />

      <AdditionalBaggageSelector
        selectedOptions={additionalBags}
        options={bags}
        error={additionalBagError}
        onSelect={handleSelectBaggage}
      />

      <LoyaltyProgramSelector
        loyaltyNumber={passenger.loyaltyNumber}
        passengerId={passenger.id}
        airlines={airlines}
        touched={touched?.loyaltyNumber}
        errors={errors?.loyaltyNumber}
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
      />
    </div>
  );
};

export default InternalPassengerForm;
