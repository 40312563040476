import request from "../../utils/request";

const formatWarning = (warnings: string[]): string => {
  return warnings.join('. ');
};

export const handleFetchWarnings = async (userId: number, setWarning: (warning: string) => void) => {
  try {
    const response = await request.get(`white-label/user/warnings/${userId}`);

    if (response?.data?.warnings?.length) {
      setWarning(formatWarning(response.data.warnings));
    } else {
      setWarning(null);
    }
  } catch (err) {
    setWarning(null);
  }
};
